import React,{ useState, useRef} from 'react';
import {Dialog} from 'primereact/dialog';
import {InputTextarea} from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';  
import { NavegadorImpugnaciones } from './navegadorImpugnaciones';
import { ProgressSpinner } from 'primereact/progressspinner';
import api from '../../interceptor/axios';


export const Impugnaciones = ( {pregunta}) => {
    const restUrlBase = process.env.REACT_APP_REST_URL;
    const [mostrarModal, setMostrarModal] = useState(false);
    const [argumentacion, setArgumentacion] = useState('');
    const [impugnando, setImpugnando] = useState(false);

    const toast = useRef(null);

    const cerrarModal = () => {
        setMostrarModal(false);
        setArgumentacion('');
        setImpugnando(false);
    };

    const impugnarPregunta = () => {
        if(argumentacion === '' || argumentacion.length < 10){
            toast.current.show({severity: 'warn', summary: 'Atención', detail: 'Debes argumentar tu impugnación con al menos 10 caracteres'});
            return;
        } else {
            setImpugnando(true);
            api.post(restUrlBase + "test/impugnar.php", { idPregunta: pregunta.ID, argumentacion: argumentacion })
            .then(response => {
                
                if (response.data.success == false) {
                    toast.current.show({severity: 'error', summary: 'Error', detail: response.data.message});
                } else {
                    toast.current.show({severity: 'success', summary: 'Impugnación realizada', detail: 'Tu impugnación ha sido registrada correctamente'});
                    cerrarModal();
                }                
            }
            ).catch(error => {
                toast.current.show({severity: 'error', summary: 'Error', detail: 'Se ha producido un error al intentar impugnar la pregunta'});
            }
        );
            
        }
    };

    return (<>
        <Toast ref={toast} />
        <Dialog header="Impugnar pregunta" visible={mostrarModal} style={{ width: '50vw' }} onHide={cerrarModal}>
                <p className="m-0">
                    Por favor, argumenta tu razón para impugnar la pregunta.
                </p>
                <InputTextarea value={argumentacion} onChange={(e) => setArgumentacion(e.target.value)} rows={5} cols={30} />
                <br />
                {pregunta.impugnaciones !== undefined && pregunta.impugnaciones != null && pregunta.impugnaciones.length > 0 && (
                    <NavegadorImpugnaciones impugnaciones={pregunta.impugnaciones} />
                )}
                { impugnando ? (
                    <ProgressSpinner className='float-right' />
                ) : (
                    <button type='button' className='btn btn-hover iq-button float-right mt-1' onClick={impugnarPregunta}>Impugnar</button>
                ) }
                
                
            </Dialog>
        <button type='button' className='d-none d-lg-block btn btn-hover iq-button ml-4' onClick={() => setMostrarModal(true)}><i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>Impugnar esta pregunta</button>
        <button type='button' className='d-xs-block d-lg-none btn btn-hover iq-button ml-4' onClick={() => setMostrarModal(true)}><i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i></button>
        </>
    );
};

export default Impugnaciones;