import "../assets/css/lluvia.css";

import React, { useEffect } from "react";
import "../assets/css/lluvia.css";

const RainEffect = ({ nota, onClose, correctas, erroneas, enBlanco,  metodoCorreccion }) => {

    const rain = () => {
        let cloud = document.querySelector('.cloud');
        if(!cloud) return;
        let e = document.createElement('div');
        let left = Math.floor(Math.random() * 310);
        let size = Math. random() * 1.5;
        let duration = Math.random() * 1;

        e.classList.add('gotas');
        cloud.appendChild(e);
        e.innerText = '|';
        e.style.left = left + 'px';
        e.style.fontSize = 0.5 + size + 'em';
        e.style.animationDuration = 1 + duration + 's';

        setTimeout(() => {
        cloud.removeChild(e);
        }, 2000);

    };

    useEffect(() => {
        setInterval(() => {
        rain();
        }, 20);
    });

    if(nota === undefined || nota === null){
      nota = 0;
    }

  return (
    <>
    <div className="boton-cerrar" onClick={onClose}>X</div>
    <div className="resumen-nota">
      <div className="aciertos">Preguntas correctas: {correctas} </div>
      <div className="fallos">Preguntas falladas: {erroneas} </div>
      <div className="blancos">Preguntas en blanco: {enBlanco} </div>
      <div className="formula-correccion">Formula de corrección: {metodoCorreccion}</div>
    </div>
    <div className="cloud-container">        
        <div className="cloud"></div>
        <div className="nota">{nota}</div>
    </div>
    </>
  );    
};

export default RainEffect;


