import React,{ useState,useRef,useEffect, Component, forwardRef} from 'react'     
import { ProgressSpinner } from 'primereact/progressspinner';  
import { Toast } from 'primereact/toast';      
import {Container,Row,Col,OverlayTrigger,Tooltip} from 'react-bootstrap';
import { Badge } from 'primereact/badge';
import { Dropdown } from 'primereact/dropdown';
import api from '../../../interceptor/axios';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Corrector } from '../../../components/test/corrector';
import { Pregunta } from '../../../components/test/pregunta';
import { Botonera } from '../../../components/test/botonera';
import { SelectorPregunta } from '../../../components/test/selectorPregunta';
import { Dialog } from 'primereact/dialog';
import { use } from 'react';
        

class TemporizadorClass extends Component {
    constructor(props) {
      super(props);
      let segundosRestantes = props.segundos;
      //pasamos a minutos y segundos
        let minutos = Math.floor(segundosRestantes / 60);
        let segundos = segundosRestantes - minutos * 60;
        let estilo = "";
      this.state = {
        minutos: minutos,
        segundos: segundos,
        estilo: estilo
      };
      this.stateOriginal = this.state;
    }
  
    componentDidMount() {
      this.intervalId = setInterval(this.actualizarTiempo, 1000);
    }
  
    componentWillUnmount() {
      clearInterval(this.intervalId);
    }

    resetTiempo = () => {
        this.setState(this.stateOriginal);
    };
  
    actualizarTiempo = () => {
      let { minutos, segundos, estilo } = this.state;
      const { onTiempoAgotado } = this.props;
    
      if (minutos == 0 && segundos == 0) {
        //clearInterval(this.intervalId);
        if(onTiempoAgotado){
            onTiempoAgotado();
        }
        this.setState(this.stateOriginal);
      } else {
        segundos--;
        if(segundos < 0){
            segundos = 59;
            minutos--;
        }
        if(minutos < 0){
            minutos = 0;
        }
        if(minutos == 0 && segundos < 10){
            estilo = "tiempo-agotandose";
        }
        this.setState({ minutos: minutos, segundos: segundos, estilo: estilo });
      }
    };
  
    render() {
      const { minutos, segundos, estilo } = this.state;
  
      return (
        <h3 className={`slider-text title text-uppercase mr-4 ${estilo}`}>
        {minutos.toString().padStart(2, '0')}:{segundos.toString().padStart(2, '0')}
      </h3>
      );
    }
  }

const Temporizador = forwardRef((props, ref) => <TemporizadorClass {...props} ref={ref} />);
export { Temporizador}
  
const Test = () => { 

    if(sessionStorage.getItem('test') == null){
        window.location.href = '/tests';
    }

    const restUrlBase = process.env.REACT_APP_REST_URL;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const [indiceActual, setIndiceActual] = useState(0);
    const [datos, setDatos] = useState(JSON.parse(sessionStorage.getItem('test')));
    const [preguntas, setPreguntas] = useState(datos.preguntas);
    const [nota, setNota] = useState(null);
    const [correctas, setCorrectas] = useState(0);
    const [erroneas, setErroneas] = useState(0);
    const [noContestadas, setNoContestadas] = useState(0);
    const [testFinalizado, setTestFinalizado] = useState(datos.finalizado);
    
    const temporizadorRef = useRef(null);
    const [empezar, setEmpezar] = useState(!datos.modoInfierno);
    let segundosRestantes = null;

    if(datos.tiempo !== undefined){
        segundosRestantes = datos.tiempo * 60; //el tiempo viene en minutos
        //calculamos los segundos que han pasado desde datos.fechaInicio hasta ahora
        let fechaInicio = new Date(datos.fechaInicio);
        let fechaActual = new Date();
        let segundosTranscurridos = Math.round((fechaActual - fechaInicio) / 1000);
        //restamos los segundos transcurridos al tiempo total del test
        segundosRestantes = segundosRestantes - segundosTranscurridos;
    } else if(datos.modoInfierno){
        segundosRestantes = 15;
    }

    for(let i = 0; i < preguntas.length; i++){
        if(preguntas[i].respuestaSeleccionada == undefined){
            preguntas[i].respuestaSeleccionada = 0;
        } 
        if(preguntas[i].estado == undefined){
            preguntas[i].estado = 'activa';
        }
    }

    
    if(datos.finalizado == undefined){
        datos.finalizado = false;
        setTestFinalizado(false);
    }

    useEffect(() => {
        datos.nota = nota;
        datos.finalizado = testFinalizado;
        sessionStorage.setItem('test', JSON.stringify(datos));
    }, [testFinalizado, nota]);

    const cambioPregunta = (indexPregunta) => {
        if(indexPregunta < preguntas.length){
            setIndiceActual(indexPregunta);
        } else {
            corregirTest();
        }
    }; 

    const navegarPregunta = (indexPregunta) => {
        setIndiceActual(indexPregunta);
        let id = preguntas[indexPregunta].ID;
        //hacemos un salto hasta ese div pero subiendo 50px arriba
        let preguntaDiv = document.getElementById('pregunta-' + id);
        if(preguntaDiv){
            preguntaDiv.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            setTimeout(() => {
                window.scrollBy({ top: -100, left: 0, behavior: 'smooth' });
            }, 1000); // Pequeño retraso para asegurar que el scrollIntoView termine
        }        
    };

    const cambioRespuesta = (indexRespuesta) => {
        const newPreguntas = [...preguntas];
        newPreguntas[indiceActual].respuestaSeleccionada = indexRespuesta;
        setPreguntas(newPreguntas);
        if(datos.modoInfierno){
            temporizadorRef.current.resetTiempo();
            cambioPregunta(indiceActual + 1);  
        }
    };

    const anteriorPregunta = () => {
        if(indiceActual > 0){
            setIndiceActual(indiceActual - 1);
        }
    };

    const siguientePregunta = () => {
        if(indiceActual < preguntas.length - 1){
            setIndiceActual(indiceActual + 1);
        }
    };

    const marcarPreguntaFallada = (idPregunta, fallada) => {
        api.post(restUrlBase + "test/preguntaFallada.php", { idPregunta: idPregunta, fallada: fallada })
            .then(response => {
                
                if (response.data.success == false) {
                    console.log("error al marcar pregunta fallada");
                } 
                
            }
            ).catch(error => {
                console.log("error al marcar pregunta fallada");
                //este error es silencioso para no cortar el flujo de trabajo
            }
        );
    };

    const corregirPregunta = () => {
        const newPreguntas = [...preguntas];
        if(newPreguntas[indiceActual].estado == 'activa'){
            if(newPreguntas[indiceActual].respuestaCorrecta == newPreguntas[indiceActual].respuestaSeleccionada){
                marcarPreguntaFallada(newPreguntas[indiceActual].ID, 'N');
                newPreguntas[indiceActual].estado = 'correcta';
            } else if(newPreguntas[indiceActual].respuestaSeleccionada !== 0) {
                marcarPreguntaFallada(newPreguntas[indiceActual].ID, 'S');
                newPreguntas[indiceActual].estado = 'erronea';
            } else {
                newPreguntas[indiceActual].estado = 'no-contestada';
            }
        }
        setPreguntas(newPreguntas);
    };

    const tiempoFinalizado = () => {
        if(datos.modoInfierno){
            temporizadorRef.current.resetTiempo();
            cambioPregunta(indiceActual + 1);            
        } else {
            corregirTest();
        }
    };

    const corregirTest = () => {
        setLoading(true);
        let preguntasCorrectas = 0;
        let preguntasErroneas = 0;
        let preguntasNoContestadas = 0;

        for(let index = 0; index < preguntas.length; index++){
            if(preguntas[index].respuestaCorrecta == preguntas[index].respuestaSeleccionada){
                preguntasCorrectas++;
                marcarPreguntaFallada(preguntas[index].ID, 'N');
                preguntas[index].estado = 'correcta';
            } else if(preguntas[index].respuestaSeleccionada != 0) {
                preguntasErroneas++;
                marcarPreguntaFallada(preguntas[index].ID, 'S');
                preguntas[index].estado = 'erronea';
            } else {
                preguntasNoContestadas++;
                preguntas[index].estado = 'no-contestada';
            }
        }

        setCorrectas(preguntasCorrectas);
        setErroneas(preguntasErroneas);
        setNoContestadas(preguntasNoContestadas);

        //calculo de nota
        let penalizacion = 2;
        if(datos.metodoCorreccion == "2-1"){
            penalizacion = 2;
        } else if(datos.metodoCorreccion == "3-1"){
            penalizacion = 3;
        } else if(datos.metodoCorreccion == "4-1"){
            penalizacion = 4;
        }
        let puntuacion = (preguntasCorrectas - (preguntasErroneas / penalizacion)) / preguntas.length * 10;
        if(puntuacion < 0){
            puntuacion = 0;
        }
        puntuacion = Math.round((puntuacion + Number.EPSILON) * 100) / 100;
        setNota(puntuacion);
        setTestFinalizado(true);

        if(datos.ID !== 0 && datos.ID !== undefined){
            //guardamos nota en el servidor
            api.post(restUrlBase + "test/guardarNota.php", { idTest: datos.ID, nota: puntuacion })
            .then(response => {
                setLoading(false);

                if (response.data.success) {
                    toast.current.show({ severity: 'success', summary: 'Nota guardada correctamente', detail: '' });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
                }
                
            })
            .catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            });
        } else {
            //pausa dramatica para crear suspense
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    };

    const reject = () => {

    }

    const confirmCorregir = () => {
            confirmDialog({
                message: '¿Quieres terminar y corregir el test?',
                header: 'Atención',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Sí',
                rejectLabel: 'No',
                accept: corregirTest,
                reject
            });
        };

    if(loading){
        return (
            <div className="card flex justify-content-center loading">
                <ProgressSpinner />
            </div>
        );
    } else if(empezar == false) {
        return (
            <Dialog visible={!empezar} style={{ width: '50vw' }} onHide={() => {}} draggable={false} resizable={false} modal={true} showHeader={false}>
                <div className='container'>
                    <h3 className='text-center text-primary'>Bienvenid@ al modo infierno</h3>
                    <p>Este modo presenta un desafío con varias dificultades añadidas.</p>
                    <ul>
                        <li>Dispondrás de 15 segundos máximo por pregunta para responder</li>
                        <li>No podrás navegar por las preguntas, tendrás que ir en orden</li>
                        <li>Cuando finalice el tiempo, pasará automaticamente a la siguiente pregunta</li>
                        <li>Sólo podrás marcar una respuesta correcta, es decir, al marcar una respuesta, pasarás a la siguiente pregunta sin opción a cambiarla</li>
                    </ul>
                    <p>Con este modo se busca entrenar el tipo test con un punto extra de presión, para que puedas mejorar tu capacidad de respuesta y concentración.</p>
                    <p>Pulsa el botón "COMENZAR" cuando estés preparado.</p>
                    <div className='text-center'>
                        <button className='btn btn-primary' onClick={() => {setEmpezar(true);}}>COMENZAR</button>
                    </div>
                </div>
            </Dialog>
        );
    } else {
        return (
            <>
            <Toast ref={toast} />
            <div className="main-content">
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <h5 className="slider-text title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">{datos.test_nombre}</h5>
                            <h5 className="slider-text title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">{datos.test_fecha}</h5>
                        </Col>
                    </Row>
                </Container>                
                { testFinalizado ? (
                    <>
                        <Container fluid>
                            <Row>
                                <Col sm={12} lg={3}>
                                    <SelectorPregunta preguntas={preguntas} indiceActual={indiceActual} notifyPreguntaActual={navegarPregunta} />
                                </Col>
                                <Col sm={12} lg={8}>
                                    <Corrector preguntas={preguntas} puntuacion={nota} correctas={correctas} erroneas={erroneas} enBlanco={preguntas.length - correctas - erroneas} metodoCorreccion={datos.metodoCorreccion} />
                                </Col>
                            </Row>
                        </Container>
                    </>
                ) : (
                    <>
                        
                        
                            <Container fluid>
                                <Row>
                                    <Col sm={12}>
                                        <div className="border-round border-1 surface-border p-4 surface-card d-flex">
                                            {segundosRestantes && testFinalizado == false && (
                                                <>
                                                <Temporizador segundos={segundosRestantes} onTiempoAgotado={tiempoFinalizado} ref={temporizadorRef}/>
                                                </>
                                            )}       
                                            
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} lg={3}>
                                        {!datos.modoInfierno && (
                                            <SelectorPregunta preguntas={preguntas} indiceActual={indiceActual} notifyPreguntaActual={cambioPregunta} />
                                        )}
                                    </Col>
                                    <Col sm={12} lg={8}>
                                        <div>
                                            {nota !== null && (
                                                        <h4 className="slider-text title text-uppercase mt-4 mb-4" data-iq-gsap="onStart" data-iq-position-x="-200">{'Tu nota: ' + nota}</h4>
                                                    )} 
                                            <Pregunta pregunta={preguntas[indiceActual]} notifyCambioRespuesta={cambioRespuesta} modoAyudaActivado={datos.modoAyuda} permiteImpugnar={false} />
                                            {!datos.modoInfierno && (
                                                <Botonera modoAyudaActivado={datos.modoAyuda && preguntas[indiceActual].estado == 'activa'} ordenActual={indiceActual} numPreguntas={preguntas.length} testFinalizado={testFinalizado} notifyAnteriorPregunta={anteriorPregunta} notifySiguientePregunta={siguientePregunta} notifyCorregirPregunta={corregirPregunta} funcionCorregir={confirmCorregir}  />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        
                    </>
                )}
            </div>
            </>
        );
    } 
}

export default Test; 