import {Container,Row,Col,OverlayTrigger,Tooltip} from 'react-bootstrap'
import { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Knob } from 'primereact/knob';   
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

export const ConfiguradorTest = ({onConfig}) => {

    
    const metodosDeCorreccion = [
        { name: 'Selecciona un método de corrección', code: '' },
        { name: '2-1', code: '2-1' },
        { name: '3-1', code: '3-1' },
        { name: '4-1', code: '4-1' }
    ];

    const minPreguntas = 10;
    const maxPreguntas = 100;
    const stepsPreguntas = 1;

    const [numPreguntasTestPersonalizado, setNumPreguntasTestPersonalizado] = useState(minPreguntas);
    const [metodoCorreccion, setMetodoCorreccion] = useState(metodosDeCorreccion[0]);
    const [tiempoMinutos, setTiempoMinutos] = useState(30);
    const [sinTiempo, setSinTiempo] = useState(false);
    const [conAyuda, setConAyuda] = useState(false);
    const [modoInfierno, setModoInfierno] = useState(false);

    const prepararConfig = () => {
        let config = {};
        config.metodoCorreccion = metodoCorreccion.code;
        config.numPreguntas = numPreguntasTestPersonalizado;
        config.tiempoMinutos = sinTiempo ? 0 : tiempoMinutos;
        config.modoAyuda = conAyuda;
        config.modoInfierno = modoInfierno;
        config.sinTiempo = sinTiempo;
        onConfig(config);
    };

    const quitarPregunta = () => {
        if (numPreguntasTestPersonalizado > minPreguntas){
            setNumPreguntasTestPersonalizado(numPreguntasTestPersonalizado - 1);
        }
    };

    const addPregunta = () => {
        if (numPreguntasTestPersonalizado < maxPreguntas){
            setNumPreguntasTestPersonalizado(numPreguntasTestPersonalizado + 1);
        }
    };

    const quitarMinuto = () => {
        if (tiempoMinutos > 5){
            setTiempoMinutos(tiempoMinutos - 1);
        }
    };

    const addMinuto = () => {
        if (tiempoMinutos < 120){
            setTiempoMinutos(tiempoMinutos + 1);
        }
    };


        return (
            <>
                    <Row>
                        <Col xs={12} sm={12} md={4}>
                            <div className='form-group'>
                                <label>Nº de preguntas</label>
                                <Knob value={numPreguntasTestPersonalizado} onChange={(e) => setNumPreguntasTestPersonalizado(e.value)} min={minPreguntas} max={maxPreguntas} step={stepsPreguntas} 
                                    valueColor='#e50914'/>
                                <Button icon="pi pi-minus" className='mr-1 boton-primario' onClick={quitarPregunta} />   
                                <Button icon="pi pi-plus" className='ml-1 boton-primario' onClick={addPregunta} />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                            <div className='form-group'>
                                <label>Tiempo (minutos)</label>
                                <Knob disabled={sinTiempo || modoInfierno} value={tiempoMinutos} onChange={(e) => setTiempoMinutos(e.value)} min={5} max={120} step={stepsPreguntas} 
                                    valueColor='#e50914'/>
                                <Button icon="pi pi-minus" className='mr-1 boton-primario' onClick={quitarMinuto} disabled={sinTiempo || modoInfierno}/>   
                                <Button icon="pi pi-plus" className='ml-1 boton-primario' onClick={addMinuto} disabled={sinTiempo || modoInfierno}/>
                                <br />
                                <label>Sin tiempo</label>
                                <br />
                                <Checkbox onChange={e => setSinTiempo(e.checked)} checked={sinTiempo} disabled={modoInfierno}></Checkbox>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                            <div className='form-group'>
                                <label>Método de corrección</label>
                                <Dropdown value={metodoCorreccion} onChange={(e) => setMetodoCorreccion(e.value)} options={metodosDeCorreccion} optionLabel="name" 
                                    placeholder="Selecciona un método de corrección" className="w-100 mt-4 mb-4" />
                            </div>
                        </Col>
                    </Row> 
                
                    
                <div className='row'>
                    <div className='form-group col-xs-12 col-md-6'>
                        <label>Ayuda en tiempo real</label>
                        <br />
                        <Checkbox onChange={e => setConAyuda(e.checked)} checked={conAyuda} disabled={modoInfierno}></Checkbox>
                        <div><small>Si se activa, antes de cambiar de pregunta podrás comprobar tu respuesta</small></div>
                    </div>
                    <div className='form-group col-xs-12 col-md-6'>
                        <label>Modo infierno</label>
                        <br />
                        <Checkbox onChange={e => setModoInfierno(e.checked)} checked={modoInfierno}></Checkbox>
                        <div><small>Tienes 15 segundos para responder cada pregunta, sin opción de avanzar o retroceder.</small></div>
                    </div>
                </div>   

                <button type='button' className='btn btn-hover iq-button' onClick={prepararConfig}><i className="fa fa-send mr-1" aria-hidden="true"></i>Empezar</button>             
            </>
        );
};

export default ConfiguradorTest;