export const Botonera = ({modoAyudaActivado, ordenActual, numPreguntas, testFinalizado, notifyAnteriorPregunta, notifySiguientePregunta, notifyCorregirPregunta, funcionCorregir}) => {

    return (
        <div className="d-flex justify-content-begin mt-4">
                <button disabled={ordenActual == 0} type='button' className='d-none d-lg-block btn btn-hover iq-button ml-4' onClick={notifyAnteriorPregunta}><i className="fa fa-backward mr-1" aria-hidden="true"></i>Anterior</button>
                <button disabled={ordenActual == 0} type='button' className='d-xs-block d-lg-none btn btn-hover iq-button ml-4' onClick={notifyAnteriorPregunta}><i className="fa fa-backward mr-1" aria-hidden="true"></i></button>
                <button disabled={ordenActual == numPreguntas - 1} type='button' className='d-none d-lg-block btn btn-hover iq-button ml-4' onClick={notifySiguientePregunta}><i className="fa fa-forward mr-1" aria-hidden="true"></i>Siguiente</button>
                <button disabled={ordenActual == numPreguntas - 1} type='button' className='d-xs-block d-lg-none btn btn-hover iq-button ml-4' onClick={notifySiguientePregunta}><i className="fa fa-forward mr-1" aria-hidden="true"></i></button>
                {modoAyudaActivado && !testFinalizado && (
                    <>
                    <button type='button' className='d-none d-lg-block btn btn-hover iq-button ml-4' onClick={notifyCorregirPregunta}><i className="fa fa-question mr-1" aria-hidden="true"></i>Corregir pregunta</button>
                    <button type='button' className='d-xs-block d-lg-none btn btn-hover iq-button ml-4' onClick={notifyCorregirPregunta}><i className="fa fa-question mr-1" aria-hidden="true"></i></button>
                    </>
                )}
                {!testFinalizado && (
                    <>
                    <button type='button' className='d-none d-lg-block btn btn-hover iq-button ml-4' onClick={funcionCorregir}><i className="fa fa-edit mr-1" aria-hidden="true"></i>Terminar y corregir</button>
                    <button type='button' className='d-xs-block d-lg-none btn btn-hover iq-button ml-4' onClick={funcionCorregir}><i className="fa fa-edit mr-1" aria-hidden="true"></i></button>
                    </>
                )}
          </div>
    );
};

export default Botonera;