import React from "react";
import confetti from "canvas-confetti";
import "../assets/css/confetti.css";

const Confeti = ( { nota, onClose, correctas, erroneas, enBlanco,  metodoCorreccion}) => {
  confetti({
    particleCount: 500,
    spread: 70,
    origin: { y: 0.6 }, // Punto de origen
  });

  return (
    <>
    <div className="boton-cerrar" onClick={onClose}>X</div>
    <div className="resumen-nota-confetti">
      <div className="aciertos">Preguntas correctas: {correctas} </div>
      <div className="fallos">Preguntas falladas: {erroneas} </div>
      <div className="blancos">Preguntas en blanco: {enBlanco} </div>
      <div className="formula-correccion">Formula de corrección: {metodoCorreccion}</div>
    </div>
    <div className="nota-confetti">{nota}</div>
  </>);
};

export default Confeti;
