import { useState } from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { Pregunta } from './pregunta';
import Confeti from '../confeti';
import Lluvia from '../lluvia';

export const Corrector = ({ preguntas, puntuacion, correctas, erroneas, enBlanco, metodoCorreccion }) => {   

    const [mostrarNota, setMostrarNota] = useState(true);
    const ocultarNota = () => {
      setMostrarNota(false); 
    };

    return (
        <>

        <Dialog showHeader={false} visible={mostrarNota && puntuacion < 5} onHide={() => ocultarNota} className='modal-nota'>
            <Lluvia nota={puntuacion} onClose={() => setMostrarNota(false)} correctas={correctas} erroneas={erroneas} enBlanco={enBlanco} metodoCorreccion={metodoCorreccion} />
        </Dialog>

        <Dialog showHeader={false} visible={mostrarNota && puntuacion >= 5} className='modal-nota-confeti'>
            <Confeti nota={puntuacion} onClose={() => setMostrarNota(false) } correctas={correctas} erroneas={erroneas} enBlanco={enBlanco} metodoCorreccion={metodoCorreccion} />
        </Dialog>
            
    
        <div>
            <h2>Nota: {puntuacion}</h2>
            <ul>
                <li>Fórmula de corrección: {metodoCorreccion}</li>
                <li>Correctas: {correctas}</li>
                <li>Erróneas: {erroneas}</li>
                <li>En blanco: {enBlanco}</li>
            </ul>
        </div>
        <div className='w-100'>
            {preguntas.map((pregunta, index) => { 
                return (
                    <Pregunta key={index} pregunta={pregunta} respuestaMarcada={pregunta.respuestaSeleccionada} notifyCambioRespuesta={() => {}} modoAyudaActivado={true} permiteImpugnar={true} />
                );
            })}
        </div>                     
        </>
    );
};

export default Corrector;
