import React, { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import api from '../../../interceptor/axios';
import { useHistory } from 'react-router-dom';
import {Container,Row,Col} from 'react-bootstrap'
import cabecera from '../../../assets/images/aula.jpg'
import { use } from 'react';
import { descargarPDF } from '../../../utils/descargarPDF';

const Recursos = () => { 
    const restUrlBase = process.env.REACT_APP_REST_URL;
    const [datos, setDatos] = useState(null);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);

    const descargar = async (recurso) => {
        try{
            await descargarPDF('R', recurso.slug, recurso.nombre);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
        }
    };   

    if(datos === null){
        api.post(restUrlBase + "recursos.php")
        .then(response => {
            if (response.data.success) {
                setDatos(response.data);
             } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
             }
             setLoading(false);
        })
        .catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
        });

        return (
                    <>
                        <Toast ref={toast} />
                        <div className="card flex justify-content-center loading">
                            <ProgressSpinner />
                        </div>
                    </>
                );
    } else {

    

        return (
            <>
            <Toast ref={toast} />
            <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{backgroundImage: `url(${cabecera})`}}>  
                <Container fluid> 
                    <Row className="align-items-center">
                        <Col sm="12">
                            <nav className="text-center iq-breadcrumb-two">
                                <h2 className="title">RECURSOS</h2>
                            </nav>
                        </Col>
                    </Row> 
                </Container>
            </div>
            <section class="mt-4">
                <Container fluid>
                    <Row>
                        {datos.categorias.map((categoria, index) => {
                            return (
                                <Col sm="12" md="4" className="overflow-hidden d-flex flex-column">
                                    <h5 class="main-title" style={{textDecoration: 'underline'}}>{categoria.nombre}</h5>  
                                    {categoria.recursos.map((recurso, index) => {
                                        return (
                                            <button type='button' className='mt-4 btn btn-hover iq-button' onClick={() => descargar(recurso)}><i className="fa fa-download mr-1" aria-hidden="true"></i>{recurso.nombre}</button>
                                        );
                                    })}                              
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </section>
            </>
        );
    }
};

export default Recursos;