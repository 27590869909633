import { Switch, Route, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group";

//App
import UserProfile from '../views/backend/app/usermanagement/userprofile'

//Extrapages
import TermsOfUse from '../views/backend/pages/extrapages/termsOfUse'
import PrivacyPolicy from '../views/backend/pages/extrapages/privacyPolicy'
import Contact from '../views/backend/pages/contact'

// Home
import Homepage from '../views/backend/home/home'

// Error 404
import Pagina404 from '../views/backend/home/pagina404';

// Leccion
import Leccion from '../views/backend/home/leccion';

// Tema
import Tema from '../views/backend/home/tema';

// Dashboard
import Dashboard from '../views/backend/home/dashboard'

// Tests
import ListaTests from '../views/backend/home/tests';

// Entrenamientos
import Entrenamientos from '../views/backend/home/entrenamientos';

// Recursos
import Recursos from '../views/backend/home/recursos';

// Test
import Test from '../views/backend/home/test';

const Layout1Route = () => {

    let location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition
                // key={location.key}
                classNames="fade"
                timeout={300}
            >
                <Switch location={location}>

                    {/* App */}
                    <Route path="/manage-profile" component={UserProfile} />

                    {/* Extrapages */}
                    <Route path="/terms-of-service" component={TermsOfUse} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/contact" component={Contact} />

                    {/* Home */}
                    <Route path="/home" component={Homepage} />

                    {/* Error */}
                    <Route path="/404" component={Pagina404} />

                    {/* Leccion */}
                    <Route path="/leccion" component={Leccion} />

                    {/* Tema */}
                    <Route path="/tema" component={Tema} />

                    {/* Tests */}
                    <Route path="/tests" component={ListaTests} />

                    {/* Entrenamientos */}
                    <Route path="/entrenamientos" component={Entrenamientos} />

                    {/* Recursos */}
                    <Route path="/recursos" component={Recursos} />

                    {/* Test */}
                    <Route path="/test" component={Test} />

                    {/* dashboard */}
                    <Route path="/" exact component={() => <Dashboard oposicion="1" />} />
                    <Route path="/ccvv" exact component={() => <Dashboard oposicion="1" />} />
                    <Route path="/sepei" exact component={() => <Dashboard oposicion="2" />} />

                    


                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default Layout1Route