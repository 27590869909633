import React,{ useState} from 'react'
import {Card} from 'react-bootstrap';
import {Dialog} from 'primereact/dialog';
import { useRef } from 'react';
import api from '../../interceptor/axios';
import { Checkbox } from 'primereact/checkbox';
import { Impugnaciones } from './impugnaciones';
import { Toast } from 'primereact/toast';

export const Pregunta = ({pregunta, notifyCambioRespuesta, modoAyudaActivado, permiteImpugnar}) => {
    const restUrlBase = process.env.REACT_APP_REST_URL;
    const [modalAyudaVisible, setModalAyudaVisible] = useState(false);
    const [favorita, setFavorita] = useState(pregunta.favorita);
    const toast = useRef(null);

    
    const cambioRespuesta = (checked, orden) => {
        if(checked === false){
            orden = 0;
        }
        notifyCambioRespuesta(orden);
    }

    const addFavorita = () => {
        api.post(restUrlBase + "test/preguntaFavorita.php", { idPregunta: pregunta.ID, favorita: 'S' }).then(response => {
                
                if (response.data.success == false) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
                } else {
                    setFavorita(1);
                    toast.current.show({ severity: 'success', summary: 'Pregunta añadida a favoritas', detail: '' });
                }            
            }).catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            }
         );
    };

    const quitarFavorita = () => {
        api.post(restUrlBase + "test/preguntaFavorita.php", { idPregunta: pregunta.ID, favorita: 'N' }).then(response => {
                
            if (response.data.success == false) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            } else {
                setFavorita(0);
                toast.current.show({ severity: 'success', summary: 'Pregunta eliminada de favoritas', detail: '' });
            }            
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
        }
     );
    };


   
    return (
        <>
            <Toast ref={toast} />
            <Dialog header="Ayuda del profesor" visible={modalAyudaVisible} style={{ width: '50vw' }} onHide={() => {if (!modalAyudaVisible) return; setModalAyudaVisible(false);}}>
                <p className="m-0">{pregunta.ayuda_impugnaciones}</p>
            </Dialog>
            
            {
                pregunta.imagen !== null && pregunta.imagen !== '' && (
                    <Card className="card p-4 mb-4">
                        <Card.Body className="ml-4 mt-4">
                            <img src={pregunta.imagen} className="img-fluid" />
                            <p className="mt-4 card-title">{pregunta.textoImagen}</p>
                        </Card.Body>
                    </Card>
                )
            }
            <Card className="card p-4" id={'pregunta-' + pregunta.ID}>
                    <h4 className="card-title">{(pregunta.orden) + '. ' + pregunta.texto}</h4>                
                <Card.Body className="ml-4 mt-4">
                    {pregunta.respuestas.map((respuesta, indexRespuesta) => {
                        let clase = '';
                        
                        if(pregunta.estado !== 'activa'){
                            if(pregunta.estado === 'correcta'){
                                if(respuesta.orden === pregunta.respuestaCorrecta){
                                    clase = 'correcta';
                                }
                            } else if(pregunta.estado === 'erronea'){
                                if(respuesta.orden === pregunta.respuestaSeleccionada){
                                    clase = 'erronea';
                                } else if(respuesta.orden === pregunta.respuestaCorrecta){
                                    clase = 'correcta';
                                }
                            } else if(pregunta.estado === 'no-contestada'){
                                if(respuesta.orden === pregunta.respuestaCorrecta){
                                    clase = 'correcta';
                                }
                            }
                        }

                        return (
                            <div key={indexRespuesta} className="d-flex align-items-left mb-2">
                                <Checkbox 
                                    className={clase}
                                    disabled={pregunta.estado !== 'activa' || permiteImpugnar}
                                    inputId={indexRespuesta}
                                    name="respuesta"
                                    onChange={e => cambioRespuesta(e.checked, indexRespuesta + 1)} 
                                    checked={pregunta.respuestaSeleccionada === indexRespuesta + 1} />

                                <label htmlFor={indexRespuesta} className={'ml-2 ' + clase}>
                                    {respuesta.texto}
                                </label>
                            </div>
                        );
                    })}

                    {modoAyudaActivado && (
                        <>
                        <div className="row mt-2 float-right">
                            {favorita == 1 && (
                                <>
                                    <button type='button' className='d-none d-lg-block btn btn-hover iq-button ml-4' onClick={() => quitarFavorita()}><i className="fa fa-heart mr-1" aria-hidden="true"></i>Quitar de favoritas</button>
                                    <button type='button' className='d-xs-block d-lg-none btn btn-hover iq-button ml-4' onClick={() => quitarFavorita()}><i className="fa fa-heart mr-1" aria-hidden="true"></i></button>
                                </>
                            ) }
                            {favorita == 0 && (
                                 <>
                                    <button type='button' className='d-none d-lg-block btn btn-hover iq-button ml-4' onClick={() => addFavorita()}><i className="fa fa-heart-o mr-1" aria-hidden="true"></i>Añadir a favoritas</button>
                                    <button type='button' className='d-xs-block d-lg-none btn btn-hover iq-button ml-4' onClick={() => addFavorita()}><i className="fa fa-heart-o mr-1" aria-hidden="true"></i></button>
                                </>
                            ) }
                            {permiteImpugnar && (
                                <div className='mr-4'>
                                    <Impugnaciones pregunta={pregunta} />
                                </div>
                            )}
                            {pregunta.ayuda && (
                                <div className='mr-4 texto-ayuda'>
                                    <i className='fa fa-question-circle'></i> {pregunta.ayuda}
                                </div>
                            )}
                            {pregunta.ayuda_impugnaciones && (
                                <div className='mr-2'>
                                    <a href="#" onClick={() => setModalAyudaVisible(true)} className='texto-ayuda'><i className='fa fa-info-circle'></i> Esta pregunta continene ayuda del profesor</a>                                
                                </div>
                            )}

                        </div>
                        </>
                    )}
                    
                </Card.Body>
            </Card>
        </>
    );

};

export default Pregunta;