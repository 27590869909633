import React, { useState } from 'react';
import "../../assets/css/impugnaciones.css";

export const NavegadorImpugnaciones = ({impugnaciones}) => {

    const [indiceActual, setIndiceActual] = useState(0);
    const nextReview = () => {
        if(indiceActual < impugnaciones.length - 1){
            setIndiceActual(indiceActual + 1);
        } else {
            setIndiceActual(0);
        }
    };
    const prevReview = () => {
        if(indiceActual > 0){
            setIndiceActual(indiceActual - 1);
        } else {
            setIndiceActual(impugnaciones.length - 1);
        }
    };

    return (
        <>
        <h5 className="text-center titulo-impugnaciones">{impugnaciones.length} {impugnaciones.length == 1 ? 'alumno ha' : 'alumnos han'} impugnado también esta pregunta</h5>
        {impugnaciones !== undefined && impugnaciones != null && impugnaciones.length > 0 && (
            <>
            <div class="review-container">
                <button class="nav-button" onClick={prevReview}>❮</button>
                <p class="review-text">{impugnaciones[indiceActual].motivo}</p>
                <button class="nav-button" onClick={nextReview}>❯</button>
            </div>
            </>
        )}
        </>
    );
};

export default NavegadorImpugnaciones;