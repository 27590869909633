import React,{ useState,useRef} from 'react'
import {Container,Row,Col,OverlayTrigger,Tooltip} from 'react-bootstrap'
import Card  from '../../../components/Card'
import { Accordion, AccordionTab } from 'primereact/accordion';
import ConfiguradorTest from '../../../components/test/configurador';
        
        
//axios
import api from '../../../interceptor/axios';
//skeleton
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';   
import { Dialog } from 'primereact/dialog'; 

import { ListBox } from 'primereact/listbox';    
import { Checkbox } from 'primereact/checkbox';

import cabecera from '../../../assets/images/aula.jpg'
import { useHistory } from 'react-router-dom';
import {descargarPDF} from '../../../utils/descargarPDF';



const ListaTests = () => { 
    const history = useHistory();   
    const restUrlBase = process.env.REACT_APP_REST_URL;
    const toast = useRef(null);
    const [listaTests, setListaTests] = useState(null);
    const [listaControles, setListaControles] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalPersonalizarVisible, setModalpersonalizarVisible] = useState(false);
    const [tituloModalPersonalizado, setTituloModalPersonalizado] = useState('');
    const [tipoTestPersonalizado, setTipoTestPersonalizado] = useState('');
    const [gruposTematicos, setGruposTematicos] = useState([]);
    const [grupoTematico, setGrupoTematico] = useState(null);
    const [temas, setTemas] = useState([]);
    const [vistaSeleccionGrupos, setVistaSeleccionGrupos] = useState(false);
    const [vistaSeleccionTemas, setVistaSeleccionTemas] = useState(false);
    const [temasSeleccionados, setTemasSeleccionados] = useState([]);
    const [contadorPreguntasFalladas, setContadorPreguntasFalladas] = useState(0);
    const [contadorPreguntasFavoritas, setContadorPreguntasFavoritas] = useState(0);

    const onTemasSeleccionadosChange = (e) => {
        let temas = [...temasSeleccionados];

        if (e.checked)
            temas.push(e.value);
        else
            temas.splice(temas.indexOf(e.value), 1);

        setTemasSeleccionados(temas);
    }

    const metodosDeCorreccion = [
        { name: 'Selecciona un método de corrección', code: '' },
        { name: '2-1', code: '2-1' },
        { name: '3-1', code: '3-1' },
        { name: '4-1', code: '4-1' }
    ];

    const irATest = (test) => {
        //añadimos la marca de inicio al test
        test.fechaInicio = new Date().getTime();
        sessionStorage.setItem('test', JSON.stringify(test));
        history.push('/test');
        //window.location.href = '/test';
    };

    const hacerControl = (control) => {
        setLoading(true);
        api.post(restUrlBase + "test/control.php", { idControl : control.ID })
        .then(response => {
            setLoading(false);

            if (response.data.success) {
                irATest(response.data.test);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
            }
        })
        .catch(error => {
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
        });   
      };

      const hacer = (test) => {
        setLoading(true);
        api.post(restUrlBase + "test/test.php", { idTest : test.ID })
        .then(response => {
            setLoading(false);

            if (response.data.success) {
                //definimos tiempo por defecto de 30 minutos
                response.data.test.tiempo = 30;
                //corregimos valor de metodo de correccion
                response.data.test.metodoCorreccion = response.data.test.metodo_correccion;
                irATest(response.data.test);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
            }
            
        })
        .catch(error => {
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
        });        
      };

      const tratarResponseTestPersonalizado = (response, config) => {
        setLoading(false);
        if (response.data.success) {
            let test = response.data.test;
            if(test.preguntas.length === 0){
                toast.current.show({ severity: 'warn', summary: 'Error', detail: 'No hay preguntas que cumplan los requisitos seleccionados para hacer el test' });
                return;
            }
            test.modoAyuda = config.modoAyuda;
            test.sinTiempo = config.sinTiempo;
            test.modoInfierno = config.modoInfierno;
            test.metodoCorreccion = config.metodoCorreccion;
            
            if(config.sinTiempo == false && config.modoInfierno == false){
                test.tiempo = config.tiempoMinutos;            
            }

            irATest(test);
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
        }
      };

      const personalizar = (config) => {
        if(config.metodoCorreccion === ''){
            toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Debe seleccionar un método de corrección' });
            return;
        }
        if(tipoTestPersonalizado === 'g'){
            if(grupoTematico === null){
                toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Debe seleccionar un grupo temático' });
                return;
            }
            setLoading(true);
            api.post(restUrlBase + "test/testGrupoTematico.php", { idGrupo: grupoTematico.ID, numPreguntas: config.numPreguntas })
            .then(response => {
                tratarResponseTestPersonalizado(response, config);
            }).catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            }); 
        } else if(tipoTestPersonalizado === 't'){
            if(temasSeleccionados.length === 0){
                toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Debe seleccionar al menos un tema' });
                return;
            }
            setLoading(true);
            api.post(restUrlBase + "test/testTemas.php", { temas: temasSeleccionados, numPreguntas: config.numPreguntas })
            .then(response => {
                tratarResponseTestPersonalizado(response, config);
            }).catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            });
        } else if(tipoTestPersonalizado === 'r'){
            setLoading(true);
            api.post(restUrlBase + "test/testAleatorio.php", { numPreguntas: config.numPreguntas })
            .then(response => {
                tratarResponseTestPersonalizado(response, config);
            }).catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            });
        } else if(tipoTestPersonalizado === 'e'){
            if(contadorPreguntasFalladas === 0){
                toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Actualmente no tienes preguntas falladas' });
                return;
            }
            setLoading(true);
            api.post(restUrlBase + "test/testErrores.php", { numPreguntas: config.numPreguntas })
            .then(response => {
                tratarResponseTestPersonalizado(response, config);
            }).catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            });
        } else if(tipoTestPersonalizado === 'f'){
            if(contadorPreguntasFavoritas === 0){
                toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Actualmente no tienes preguntas favoritas' });
                return;
            }
            setLoading(true);
            api.post(restUrlBase + "test/testFavoritas.php", { numPreguntas: config.numPreguntas })
            .then(response => {
                tratarResponseTestPersonalizado(response, config);
            }).catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            });
        }

      };

      const generarSlug = (nombre) => {
        return nombre
            .toLowerCase()                         // Convierte a minúsculas
            .replace(/\s+/g, '-')                   // Reemplaza espacios por guiones
            .replace(/[^\w\-]+/g, '')               // Elimina caracteres especiales
            .replace(/\-\-+/g, '-')                 // Reemplaza múltiples guiones consecutivos por uno solo
            .replace(/^-+/, '')                     // Elimina guiones al inicio
            .replace(/-+$/, '');                    // Elimina guiones al final
    };

    const descargar = async (test) => {
        try{
           await descargarPDF('T', test.ID, test.test_nombre + '.pdf');
        } catch (error) {
           toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error al descargar el test' });
        }
      };      

      const volverVistaGeneral = () => {
        setVistaSeleccionTemas(false);
        setVistaSeleccionGrupos(false);
      };

      const testGrupoTematico = () => {
        setTituloModalPersonalizado('Test por grupo temático');
        setTipoTestPersonalizado('g');
        setGrupoTematico(null);
        setVistaSeleccionGrupos(true);
      };

      const testTemas = () => {
        setTituloModalPersonalizado('Test por temas');
        setTipoTestPersonalizado('t');
        setTemasSeleccionados([]);
        setVistaSeleccionTemas(true);
      };

      const testAleatorio = () => {
        setTituloModalPersonalizado('Test aleatorio');
        setTipoTestPersonalizado('r');
        setModalpersonalizarVisible(true);
      };

      const testErrores = () => {
        if(contadorPreguntasFalladas === 0){
            toast.current.show({ severity: 'info', summary: 'Error', detail: 'Actualmente no tiene preguntas falladas' });
        } else {
            setTituloModalPersonalizado('Test de errores');
            setTipoTestPersonalizado('e');
            setModalpersonalizarVisible(true);
        }
      };

      const testFavoritas = () => {
        if(contadorPreguntasFavoritas === 0){
            toast.current.show({ severity: 'info', summary: 'Error', detail: 'Actualmente no tiene preguntas favoritas' });
        } else {
            setTituloModalPersonalizado('Test de favoritas');
            setTipoTestPersonalizado('f');
            setModalpersonalizarVisible(true);
        }
      };

      if(listaTests === null){

        api.post(restUrlBase + "listaTests.php", {  })
        .then(response => {
  

            if (response.data.success) {
                setListaTests(response.data.listaTests);
                setListaControles(response.data.controles);
                setContadorPreguntasFalladas(response.data.contadorPreguntasFalladas);
                setContadorPreguntasFavoritas(response.data.contadorPreguntasFavoritas);
                setGruposTematicos(response.data.gruposTematicos);
                setTemas(response.data.temas);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
            }
           
        })
        .catch(error => {
           toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
        });

        return (
            <>
                <Toast ref={toast} />
                <div className="card flex justify-content-center loading">
                    <ProgressSpinner />
                </div>
            </>
        );
    } else {
        if(loading){
            return (
                <div className="card flex justify-content-center loading">
                    <ProgressSpinner />
                </div>
            );
        } else {

                return (
                    <>
                    <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{backgroundImage: `url(${cabecera})`}}>  
                        <Container fluid> 
                            <Row className="align-items-center">
                                <Col sm="12">
                                    <nav className="text-center iq-breadcrumb-two">
                                        <h2 className="title">ZONA DE TEST</h2>
                                    </nav>
                                </Col>
                            </Row> 
                        </Container>
                     </div>
                    <div>
                        <Toast ref={toast} />
                        
                        <Container fluid>
                            <Row>
                                <Col sm="12" className={!vistaSeleccionTemas ? 'd-none' : 'd-block'}>
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between mt-4">
                                            <Card.Header.Title>
                                                <h4 className="main-title">Personalizar test por temas</h4>
                                            </Card.Header.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row className='mb-4 mt-4'>
                                                <button type='button' className='btn btn-hover iq-button' onClick={volverVistaGeneral}><i className="fa fa-backward mr-1" aria-hidden="true"></i>Volver</button>
                                            </Row>
                                            <Row>
                                                <Col sm="12" lg="8" className='mt-4 p-4'>

                                                    <Accordion activeIndex={0}>
                                                        {gruposTematicos.map((grupo) => {
                                                            return (
                                                                <AccordionTab key={grupo.ID} header={grupo.descripcion} className='accordion-tab'>
                                                                    {temas.filter(
                                                                        (tema) => tema.grupo_tematico === grupo.ID
                                                                    )
                                                                    .map((tema) => {
                                                                        return (
                                                                            <Col sm="12" key={tema.id}>
                                                                                <Checkbox inputId={'tema-' + tema.ID} name="temas" value={tema.ID} onChange={onTemasSeleccionadosChange} checked={temasSeleccionados.includes(tema.ID)} />
                                                                                <label htmlFor={'tema-' + tema.ID} className="ml-2">{tema.nombre}</label>
                                                                            </Col>
                                                                        )
                                                                    })}
                                                                </AccordionTab>
                                                            );
                                                        })}
                                                    </Accordion>
                                                </Col>
                                                
                                                <Col sm="12" lg="4" className='text-center mt-4 p-4'>
                                                    <ConfiguradorTest onConfig={personalizar} />
                                                </Col>
                                           </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm="12" className={!vistaSeleccionGrupos ? 'd-none' : 'd-block'}>
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between mt-4">
                                            <Card.Header.Title>
                                                <h4 className="main-title">Personalizar test por grupo temático</h4>
                                            </Card.Header.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row className='mb-4 mt-4'>
                                                <button type='button' className='btn btn-hover iq-button' onClick={volverVistaGeneral}><i className="fa fa-backward mr-1" aria-hidden="true"></i>Volver</button>
                                            </Row>
                                            <Row>
                                                <Col sm="12" lg="8" className='mt-4 p-4'>
                                                    <div className='form-group'>
                                                        <ListBox value={grupoTematico} onChange={(e) => setGrupoTematico(e.value)} options={gruposTematicos} optionLabel="descripcion" className="w-full md:w-14rem" />
                                                    </div>                                                    
                                                </Col>
                                                
                                                <Col sm="12" lg="4" className='text-center mt-4 p-4'>
                                                    <ConfiguradorTest onConfig={personalizar} />
                                                </Col>
                                           </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm="12" className={(vistaSeleccionTemas || vistaSeleccionGrupos) ? 'd-none' : 'd-block'}>
                                    <Dialog header={tituloModalPersonalizado} visible={modalPersonalizarVisible} onHide={() => setModalpersonalizarVisible(false)} >
                                        <div className='row d-flex flex-column text-center p-4'>
                                        <ConfiguradorTest onConfig={personalizar}/>
                                            {tipoTestPersonalizado === 'e' && (
                                                <div className='form-group'>
                                                    <label>Nº de preguntas falladas actualmente</label>
                                                    <div className='h1'>{contadorPreguntasFalladas}</div>
                                                </div>
                                            )}
                                            {tipoTestPersonalizado === 'f' && (
                                                <div className='form-group'>
                                                    <label>Nº de preguntas favoritas</label>
                                                    <div className='h1'>{contadorPreguntasFavoritas}</div>
                                                </div>
                                            )}
                                        </div>
                                </Dialog>
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between mt-4">
                                            <Card.Header.Title>
                                                <h4 className="main-title">Tests personalizados</h4>
                                            </Card.Header.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="row">                                                
                                                <button type='button' className='btn btn-hover iq-button mt-2 mb-2 col-12 col-lg-2 offset-lg-1' onClick={testGrupoTematico}><i className="fa fa-bars mr-1" aria-hidden="true"></i>Grupo temático</button>
                                                <button type='button' className='btn btn-hover iq-button mt-2 mb-2 col-12 col-lg-2 ml-1' onClick={testTemas}><i className="fa fa-book mr-1" aria-hidden="true"></i>Temas</button>
                                                <button type='button' className='btn btn-hover iq-button mt-2 mb-2 col-12 col-lg-2 ml-1' onClick={testAleatorio}><i className="fa fa-random mr-1" aria-hidden="true"></i>Aleatorio</button>
                                                <button type='button' className='btn btn-hover iq-button mt-2 mb-2 col-12 col-lg-2 ml-1' onClick={testErrores}><i className="fa fa-times mr-1" aria-hidden="true"></i>Errores</button>
                                                <button type='button' className='btn btn-hover iq-button mt-2 mb-2 col-12 col-lg-2 ml-1' onClick={testFavoritas}><i className="fa fa-heart mr-1" aria-hidden="true"></i>Favoritas</button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                
                                {listaControles != null && listaControles.length > 0 && (
                                <>
                                <Col sm="12" className={vistaSeleccionTemas ? 'd-none' : 'd-block'}>
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between mt-4">
                                            <Card.Header.Title>
                                                <h4 className="main-title">Controles disponibles</h4>
                                            </Card.Header.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="table-view">
                                                <table className="data-tables table movie_table " style={{width:"100%"}}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{width:"50%"}}>Nombre</th>
                                                            <th style={{width:"20%"}}>Disponible hasta</th>
                                                            <th style={{width:"10%"}}>Duración</th>
                                                            <th style={{width:"20%"}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {listaControles.map((control, indexControl) => {
                                                        return (
                                                            <tr key={indexControl}>
                                                                <td>{control.test_nombre}</td>
                                                                <td>{control.fecha_fin_test}</td>
                                                                <td>{control.tiempo_minutos} minutos</td>
                                                                <td>
                                                                {control.fecha_inicio_alumno == null && (
                                                                    <button type='button' className='btn btn-hover iq-button w-100' onClick={() => hacerControl(control)}><i className="fa fa-cog mr-1" aria-hidden="true"></i>Empezar</button>
                                                                )}
                                                                {control.fecha_inicio_alumno != null && (
                                                                    <button type='button' className='btn btn-hover iq-button w-100 mt-1' onClick={() => hacerControl(control)}><i className="fa fa-cog mr-1" aria-hidden="true"></i>Reanudar</button>
                                                                )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                </>
                                )}
                                
                                <Col sm="12" className={vistaSeleccionTemas ? 'd-none' : 'd-block'}>
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between mt-4">
                                            <Card.Header.Title>
                                                <h4 className="main-title">Últimos tests disponibles</h4>
                                            </Card.Header.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="table-view">
                                                <table className="data-tables table movie_table " style={{width:"100%"}}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{width:"60%"}}>Nombre</th>
                                                            <th style={{width:"10%"}}>Fecha</th>
                                                            <th style={{width:"10%"}}>Nota</th>
                                                            <th style={{width:"20%"}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {listaTests.map((test, indexTest) => {
                                                        return (
                                                            <tr key={indexTest}>
                                                                <td>{test.test_nombre}</td>
                                                                <td>{test.test_fecha}</td>
                                                                <td>{test.nota_fecha}<br/>{test.nota}</td>
                                                                <td>
                                                                {test.descargable ? (
                                                                    <>
                                                                    <button type='button' className='btn btn-hover iq-button w-100 boton-test' onClick={() => descargar(test)}><i className="fa fa-download mr-1" aria-hidden="true"></i>Descargar</button>
                                                                    <button type='button' className='btn btn-hover iq-button w-100 boton-test-movil' onClick={() => descargar(test)}><i className="fa fa-download mr-1" aria-hidden="true"></i></button>
                                                                    </>
                                                                ) : null }
                                                                {test.online ? (
                                                                    <>
                                                                    <button type='button' className='btn btn-hover iq-button w-100 mt-1 boton-test' onClick={() => hacer(test)}><i className="fa fa-cog mr-1" aria-hidden="true"></i>Hacer online</button>
                                                                    <button type='button' className='btn btn-hover iq-button w-100 mt-1 boton-test-movil' onClick={() => hacer(test)}><i className="fa fa-cog mr-1" aria-hidden="true"></i></button>
                                                                    </>
                                                                ) : null}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
            
                    </div>
                    </>
                )
                    
            }

    
        }
}
export default ListaTests;