import {useState} from 'react';
import {Dropdown} from 'primereact/dropdown';
import {Badge} from 'primereact/badge';

export const SelectorPregunta = ({preguntas, indiceActual, notifyPreguntaActual}) => {

    const [preguntaSeleccionada, setPreguntaSeleccionada] = useState(preguntas[indiceActual]);
    const cambioPregunta = (indexPregunta) => {
        setPreguntaSeleccionada(preguntas[indexPregunta]);
        notifyPreguntaActual(indexPregunta);
    };

    return (<>
        <div className='selectorPreguntas d-none d-lg-block'>
            {preguntas.map((pregunta, indexPregunta) => {
                const enunciadoRecortado = pregunta.texto.length > 20 ? pregunta.texto.substring(0, 20) + '...' : pregunta.texto;
                let clase = '';
                if(indexPregunta == indiceActual){
                    clase = ' actual';
                } else {
                    if(pregunta.estado == 'correcta'){
                        clase = ' correcta';
                    } else if(pregunta.estado == 'erronea'){
                        clase = ' erronea';
                    } else if(pregunta.respuestaSeleccionada !== 0){
                        clase = ' respondida';
                    } else {
                        clase = ' no-contestada';
                    }
                }
                return (
                    <div className="pregunta d-flex justify-content-start mb-1" key={indexPregunta} onClick={() => cambioPregunta(indexPregunta)}> 
                        {clase.includes("actual") && (
                                <i className='fa fa-arrow-right mr-2'></i>
                        )}                      
                        <div className={'orden' + clase}>
                            <Badge value={pregunta.orden} className='orden-pregunta'></Badge>
                        </div>
                        <div className='enunciado ml-4'>
                            {enunciadoRecortado}
                        </div>
                    </div>
                );
            })}
        </div>
        <div className='selectorPreguntas d-block d-lg-none text-center'>
            <Dropdown value={preguntaSeleccionada} onChange={(e) => cambioPregunta(e.value.orden - 1)} options={preguntas} optionLabel='texto' placeholder='Selecciona una pregunta' className='w-full' />
        </div>
        </>
    );
};

export default SelectorPregunta;